.row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1.2rem;
}

.divider {
    width: 40px;
}

.selectView {
    width: 38rem;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.5rem;
}

.form {
    border-width: 1px;
    border-color: #45ba36;
    border-style: solid;
    padding: 1rem;
}