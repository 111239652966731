.row {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
}

.main {
    margin: 0;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.form {
    border-width: 1px;
    border-color: #45ba36;
    border-style: solid;
    padding: 1rem;
    width: 70%;
    align-self: center;
}

.formRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
}

.selectView {
    width: 38rem;
    margin-top: 1.5rem;
}

.divider {
    width: 2rem;
}


.dropdownLabel {
    color: white;
    text-transform: uppercase;
}

.dropdownItem {
    width: 20rem;
}

.dropdownButton {
    background-color: white;
    border: 1px solid #45ba36;
    color: #45ba36;
    width: 24rem;
    border-radius: 6px;
    text-transform: capitalize !important;
    z-index: 1;
}